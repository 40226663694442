/** @format */

import React, { useState } from "react";
import { Container, Typography, Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(35),
  },
  content: {
    textAlign: "left",
    // make the content fit within
    // the left 50% of the screen
    width: "50%",
  },
  message: {
    fontWeight: "100 !important",
    marginBottom: theme.spacing(4),
    fontSize: "2rem",
    fontFamily: "'Ralway', sans-serif !important",
  },
  divider: {
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    height: "1px",
    width: "400px",
    marginTop: "400px",
  },
  emailLink: {
    color: "rgba(255, 255, 255, 1)",
    textDecoration: "none",
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "2px",
      bottom: 0,
      left: 0,
      backgroundColor: "rgba(255, 255, 255, 1)",
      transform: "scaleX(0)",
      transformOrigin: "bottom left",
      transition: "transform 0.3s ease-out",
    },
    "&:hover::after": {
      transform: "scaleX(1)",
    },
  },
  emailButton: {
    marginLeft: "0px !important",
    paddingLeft: "0px !important",
    marginTop: "40px !important",
    color: "rgba(255, 255, 255, 0.5) !important",
    // borderColor: "#fff !important",
    "&:hover": {
      // backgroundColor: "rgba(255, 255, 255, 0.1) !important",
      color: "#fff !important",
    },
  },
}));

const PilotTest: React.FC = () => {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box className={classes.root}>
      <Container maxWidth="xl">
        <Box className={classes.content}>
          <Typography variant="h4" className={classes.message}>
            We are excited to have you as a pilot tester! Please{" "}
            <Link
              to="/contact"
              state={{ from: "PilotTest" }}
              className={classes.emailLink}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {"get in touch"}
            </Link>{" "}
            with us if you have any questions or feedback.
          </Typography>
          <Button
            component={Link}
            to="/contact"
            state={{ from: "PilotTest" }}
            variant="outlined"
            className={classes.emailButton}
            startIcon={<EmailIcon />}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {"Contact us"}
          </Button>

          <Box className={classes.divider} />
        </Box>
      </Container>
    </Box>
  );
};

export default PilotTest;
